
/* Defining the page look and feel */

/* importing fonts*/ 

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


* {
    /* removing uneccessary margins and everything that is not needed */
    margin: 0;
    padding:0;
    border:0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    /* Defining css variables so we do not have to repeat oursleves */

    /* Defining the colors we will be using */
    --color-bg: #1f1f38; /* background color */
    --color-bg-variant: #101d42;
    --color-primary: #fff;
    --color-hover: rgb(177,15,46, 0.5);
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    /* defining layout variables */
    --container-width-lg: 75%; /* width of the container to be wrapped around different variables*/
    --container-width-md: 86%; 
    --container-width-ms: 90%;

    --transition: all 400ms ease;
}



html {
    scroll-behavior: smooth;
    /* For Dynamic Bachground */
  }


::-webkit-scrollbar {
    display: none;
} 

/* Dynamic Background */

  body {
    margin:0;
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    margin: 0;
    background-image: url(../src/assets/seamlessTile.jpg);
  }
  
  .bg {
    animation:slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #1a5ff1 50%, #09f 50%);
    bottom:0;
    left:-50%;
    opacity:.5;
    position:fixed;
    right:-50%;
    top:0;
    z-index:-1;
  }
  
  .bg2 {
    animation-direction:alternate-reverse;
    animation-duration:4s;
  }
  
  .bg3 {
    animation-duration:5s;
  }
  
  .content {
    background-color:rgba(255,255,255,.8);
    border-radius:.25em;
    box-shadow:0 0 .25em rgba(0,0,0,.25);
    box-sizing:border-box;
    left:50%;
    padding:10vmin;
    position:fixed;
    text-align:center;
    top:50%;
    transform:translate(-50%, -50%);
  }
  
  @keyframes slide {
    0% {
      transform:translateX(-25%);
    }
    100% {
      transform:translateX(25%);
    }
  }

/* ========== General Styles ===== */

.container {
    width: var(--container-width-lg);
    margin: auto;
    margin-bottom: 0;
}

h1,h2,h3,h4,h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5
}

section {
    margin-top: auto;
    height: auto;
    margin-top: 4rem;
}

section > h2,
section > h5 {
    text-align: center;
    color: var(--color-light);
}

section >h2 {
    color: var(--color-primary);
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition); 
}

a:hover{

    color: #0d1317;  

}

/* Defining buttons */


.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition)
}

.btn:hover{
    /* background: var(--color-hover);
    color: var(--color-bg);
    border-color: transparent; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: scale(1.1);
}

.btn-primary{
    background:var(--color-primary);
    color: var(--color-bg);

}

img{
    display:block;
    width: 100%;
    object-fit: cover;
}


/*  Media Classess to make is responsive */
/*  ========= Meadia Queries (Medium Devices) Tablets ========= */

@media screen and (max-width: 1024px){
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem
    }
}

/*  ========= Meadia Queries (Medium Devices) Phones ========= */

@media screen and (max-width: 600px){
    .container{
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-bottom: 2rem;
    }
}