#experience {
      background-color: #0d1317;
      height: 100%;
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      border-style: solid;
      border-color: white;
      border-bottom-width: 10px;
      border-top-width: 10px;
}

h2 {
  background-color: transparent;

}

/* The actual timeline (the vertical ruler) */
.experience__timeline {
    position: relative;
    margin: 0 auto;
  }
  
  /* The actual timeline (the vertical ruler) */
  .experience__timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }
  
  .content__experience {
    display: grid;
    margin-right: 0rem;
    grid-template-columns: 20% 70%;
    gap: 10%;
    padding: 40px 40px 40px 40px;
    position: relative;
    border-radius: 5rem;
    color: #0d1317;
    background-color: white;
    background-clip: content-box;
    /* filter: drop-shadow(-1px 1px 3px rgba(50, 50, 0, 0.5)); */
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
    width: 50%;
    align-items: center;
    justify-items: center;
    align-content: center;
  }

  .content__experience::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: white;
    border: 4px solid #0d1317;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }


  /* Place the container to the left */
.left {
    left: 0;
  }
  
  /* Place the container to the right */
  .right {
    left: 50%;
  }
  

  
  /* Fix the circle for containers on the right side */
  .right::after {
    left: -16px;
  }

  .experience__image {
    transform: scale(1.2);
    align-items: center;
    background-color: none;
    border-radius: 50%;
    margin-left: 10px;
    padding: 20px;
    filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 5px 15px);
  }