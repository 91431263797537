/* Styling the header section */

header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/*  =========== CTA ============= */
.cta {
    margin-top: 40rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ========== SOCIALS =========== */

.header__social {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8;
    position:absolute;
    left: 0;
    bottom: 10rem ;
}

.header__social::before {
    margin-bottom: 10px;
    content: '';
    width: 2px;
    height: 10rem;
    background: var(--color-primary);
}

.header__social::after {
    margin-top: 10px;
    content: '';
    width: 2px;
    height: 10rem;
    background: var(--color-primary);
}

/* =============== My Image ============= */

.me{
    background: #0D1317;
    width: 24rem;
    height: 24rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 10rem;
    border-radius: 12rem 12rem 12rem 12rem ;
    overflow: hidden;
    border-style: solid;
    border-color: white;
    border-width: 3px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


/* Scroll down  */

.scroll__down {
    position: absolute;
    right: -2rem;
    bottom: 25rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}


/*  Media Classess to make is responsive */
/*  ========= Meadia Queries (Medium Devices) Tablets ========= */

@media screen and (max-width: 1024px){
    header {
        height: 68vh;
    }

}

/*  ========= Meadia Queries (Medium Devices) Phones ========= */

@media screen and (max-width: 600px){
     header {
        height: 100vh;
    }

    .header__social,
    .scroll__down {
        display: none;
    }
    
}
