#about {
    height: 90vh;
    background-color: #0d1317;
    /* height: 120%; */
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    border-style: solid;
    border-color: white;
    border-bottom-width: 10px;
    border-top-width: 10px;
}

.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent,
    white,transparent);
    display:grid;
    place-items: center;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(-10deg);

}

.about__me-image:hover {
    transform: rotate(0);
    transition: var(--transition);
}

.about__cards {
    display: grid;
    grid-template-columns:  repeat(3, 1fr);
    gap: 1.5rem
}

/* .about__card {
    background: rgb(13,19,23, 1);
    border: 1px solid transparent ;
    padding: 2rem;
    border-radius: 1rem;
    text-align: center;
} */

.about__content p {
    margin: 2rem 0 2.6rem ;
}

/*  Media Classess to make is responsive */
/*  ========= Meadia Queries (Medium Devices) Tablets ========= */

@media screen and (max-width: 1024px){
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }
    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }

}

/*  ========= Meadia Queries (Medium Devices) Phones ========= */

@media screen and (max-width: 600px){

    .about__me {
        width: 65%;
        margin: 0rem auto 3rem;
    }

    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }
    
}