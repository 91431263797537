/* Styling the nav bar without the links */
nav {
    background: rgb(13,19,23, 0.5);
    list-style-type: none;
    /* width: max-content; */
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    margin: 0;
    z-index: 2;
    top: 0;
    position:fixed;
    width: 100% ;
    height: 3.5rem;
    gap: 5rem;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    box-shadow: 2px 2px 5px #000000;
}

/* Styling the links */

nav a { 
    background: transparent;
    padding: 1rem;
    border-radius: 25%;
    font-size: 1.5rem;
    display: flex;
    transition: 400ms;
}

nav a:hover {
    background:rgb(13,19,23, 0.7);
    color: var(--color-white);
    display:block
    
}

/* Defining icons when they are active */

nav a.active {
    background: rgb(13,19,23, 0.7) ;
}

/*  Media Classess to make is responsive */
/*  ========= Meadia Queries (Medium Devices) Tablets ========= */

@media screen and (max-width: 1024px){
    nav {
        gap: 0.8rem;
    }

    nav a {
        padding: 0.8rem;
        border-radius: 25%;
    }

}

/*  ========= Meadia Queries (Medium Devices) Phones ========= */

@media screen and (max-width: 600px){

    nav {
        gap: 0.3rem;
    }

    nav a {
        padding: 0.0rem;
        border-radius: 25%;

    }
    
    
}