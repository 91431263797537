.container__portfolio {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 200px;
    justify-items: center;
    justify-content: center;
    gap: 40px;
    padding-top: 3rem;
    /* background-color: #0d1137;
    padding: 3rem 3rem 3rem 3rem;
    border-radius: 8rem;
    box-shadow: 0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5); */
}

.container__portfolio h3 {
    color: #0d1137;
    margin-top: 100px;
    backdrop-filter: blur(10px) ;
    overflow: hidden;
    border-radius:2em;
    margin-left: 30px;
    margin-right: 30px;
}

.container__portfolio h5 {
    color: #0d1137;
    margin-top: 10px;
    backdrop-filter: blur(10px);
    margin-left: 30px;
    margin-right: 30px;
    border-radius:2em;

}



.portfolio__project {
    width: 400px;
    aspect-ratio: 1/1;
    text-align: center; 
    align-items: center;   
    text-shadow:1px 3px 8px rgba(128, 125, 112, 1);
    border-color: black;
    border-width: 10px;
    border-radius: 3rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}



.portfolio__project:hover {
    text-shadow:1px 3px 8px rgba(128, 125, 112, 1);
    border: 100px;
    border-color: black;
    transform: scale(1.1);
}

/*  ============= PROJECTS - Images ============================== */

.defense {
    background-image: url(../../assets/projects/defenseBudgetAnalysis.png);
    background-size: cover;
}

.MDM {
    background-image: url(../../assets/projects/mdm.png);
    background-size: cover;
}

.AIwellness {
    background-image: url(../../assets/projects/AIforWellness.png);
    background-size: cover;
}

.BrewAI_AutoML {
    background-image: url(../../assets/projects/brewaiautoml.png);
    background-size: cover;
}

.NAA {
    background-image: url(../../assets/projects/NAA.png);
    background-size: contain;
}

.FinReporting {
    background-image: url(../../assets/projects/finreporting.jpeg);
    background-size: contain;
}

.HateCrime {
    background-image: url(../../assets/projects/HateCrime.png);
    background-size: contain;
}

.CapitalOptimization {
    background-image: url(../../assets/projects/CapitalOptimization.jpg);
    background-size: contain;
}

.LGRForecasting {
    background-image: url(../../assets/projects/LGRforecasting.jpg);
    background-size: contain;
}

.PBEsolver {
    background-image: url(../../assets/projects/PBE.png);
    background-size: contain;
}

.VFM {
    background-image: url(../../assets/projects/VFM.jpg);
    background-size: contain;
}

.SQLyelp {
    background-image: url(../../assets/projects/DataQuerying.png);
    background-size: contain;
}

.RecommendationSystem {
    background-image: url(../../assets/projects/FoodRecipeRecoEngine.png);
    background-size: contain;
}

.staticMixer {
    background-image: url(../../assets/projects/MixingStaticMixer.jpg);
    background-size: contain;
}

.GMMStaticMixer {
    background-image: url(../../assets/projects/MLforMixing.png);
    background-size: contain;
}

/*  Media Classess to make is responsive */
/*  ========= Meadia Queries (Medium Devices) Tablets ========= */

@media screen and (max-width: 1024px){

.container__portfolio {
    margin: auto;
}

}

/*  ========= Meadia Queries (Medium Devices) Phones ========= */

@media screen and (max-width: 600px){

    .container__portfolio {
        margin: auto;
    }

    
}