.skills__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    
    gap: 2rem;
}


.skills__container > div {
    background: var(--color-primary);
    padding: 1.4rem 1rem;
    border-radius: 2rem;
    border: 3px solid transparent;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
    transition: var(--transition);
}

.skills__container > div:hover {
    background: transparent;
    border-color: var(--color-primary);
    cursor: default;
    backdrop-filter: blur(20px);
}

.skills__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-bg-variant);
}

.skills__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
    color: var(--color-bg-variant);
}

.skills__details {
    display: flex;
    gap: 1rem;
    color: var(--color-bg-variant);
}

.skills__details small {
    color: var(--color-bg-variant);
}

.skills__details-icon {
    margin-top: 6px;
    flex: 0 0 20px;
}

@media screen and (max-width: 1024px){
    .skills__container {
        grid-template-columns: 1fr;
        margin: 2rem 2rem;
    }

}

/*  ========= Meadia Queries (Medium Devices) Phones ========= */

@media screen and (max-width: 600px){

    .skills__container {
        grid-template-columns: 1fr;
        margin: 10px 10px;
    }
    
    
}
