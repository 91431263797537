#contact {
    background-color: #0d1317;
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 2rem;
    border-style: solid;
    border-color: white;
    border-bottom-width: 10px;
    border-top-width: 10px;
    border-radius: 0px;
    padding-bottom: 2rem;
}

h2 {
    padding: 0;
    margin: 0;
}

.container.contact__container {
    width: 74%;
    display: grid;
    grid-template-columns:40% 58% ;
    gap: 12%;
}


.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background-color: var(--color-primary);
    color: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    transition: var(--transition);
}

.contact__option-icon {
    font-size: 2rem;
    margin-bottom:  0.5rem;
}
.contact__option a {
    margin-top:  0.7rem;
    display: inline-block;
    font-size: 0.8rem;
    color: #1a5ff1;
}

/* ===========form=============== */

form {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin: 2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary);
    resize: none;
    color: var(--color-primary);
    font-size: large;


}


/*  Media Classess to make is responsive */
/*  ========= Meadia Queries (Medium Devices) Tablets ========= */

@media screen and (max-width: 1024px){
    .container.contact__container{
        grid-template-columns: 1fr;
        gap: 2rem
    }

}

/*  ========= Meadia Queries (Medium Devices) Phones ========= */

@media screen and (max-width: 600px){

    .container.contact__container{
        width: var(--container-width-sm);
    }
}